import Image from 'next/image';
import styles from './plan.module.scss';
import imageConfigure from '@/public/goal.png';
import PTText from '@/components/common/text/pt-text';
import { useAppSelector } from '@/redux/store';
import { useEffect, useMemo, useState } from 'react';
import PaymentForm from '@/components/payment-form/payment-form';

export interface PaidPlansProps {
  paidPlan: {
    planItems: string[];
    plan?: string;
    code: string;
    isPro: boolean;
    button: string;
  };
}

export default function Plan({ paidPlan }: PaidPlansProps) {
  const { products } = useAppSelector((state) => state.product);
  const plan = useMemo(
    () => ({
      ...products.find((product) => product.code === paidPlan.code),
      ...paidPlan,
    }),
    [products, paidPlan],
  );
  const { user } = useAppSelector((state) => state.session);
  const [referenceCode, setReferenceCode] = useState('');
  const [amount, setAmount] = useState<number>();

  useEffect(() => {
    const reference = `${plan.code}-${user?.id}-reference-${Date.now()}`;
    setReferenceCode(reference);
    const amount = Number(plan.price) * 1000;
    setAmount(amount);
  }, [user?.id, plan.code]);

  return (
    <div
      className={styles.pricingV1Card}
      style={
        !plan.isPro
          ? { backgroundColor: 'var(--white-color)' }
          : { backgroundColor: 'var(--primary-color)' }
      }
    >
      <div
        className={styles.pricingV1Badge}
        style={
          !plan.isPro
            ? { backgroundColor: 'var(--white-color)' }
            : { backgroundColor: 'var(--ternary-color)' }
        }
      >
        {plan.isPro && (
          <>
            <Image src={imageConfigure} alt="configure" height={32} />
            <PTText size="xxxs" weight="500">
              Recomendado
            </PTText>
          </>
        )}
      </div>
      <div className={styles.upper}>
        <PTText asTag="h1" size="xl" weight="700" className={styles.headingBox}>
          {plan.plan}
        </PTText>
        <div
          className={styles.smallDivider}
          style={
            plan.isPro
              ? { background: 'var(--white-color)' }
              : { background: 'var(--primary-color)' }
          }
        ></div>

        <div className={styles.pricingFeatures}>
          <div className={styles.pricingListItem}>
            <ul>
              {plan.planItems.map((plan) => (
                <li
                  key={plan}
                  className={`${!paidPlan.isPro ? 'pro' : ''} pt-list`}
                >
                  <PTText size="sm" weight="500">
                    {plan}
                  </PTText>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.discountedPriceTag}>
          <div className={styles.discountedPriceCurrency}>$</div>
          <div className={styles.declarationDiscountedPrice}>
            {plan.discountedPrice}
          </div>
          <span className={styles.discountedPriceCents}>.000</span>
        </div>

        <div className={styles.pricingTag}>
          <div className={styles.pricingCurrency}>$</div>
          <div className={styles.declarationPrice}>{plan.price}</div>
          <span className={styles.moveUpCents}>.000</span>
        </div>
      </div>

      {user?.role !== 'admin' && (
        <PaymentForm
          buttonText="¡Obtener!"
          redirectUrl="/planes"
          description={plan.plan || ''}
          reference={referenceCode}
          amount={amount || 0}
          productCode={plan.code}
          dialog={true}
        />
      )}
    </div>
  );
}
