import { findProducts } from '@/redux/actions/product.action';
import { useAppDispatch } from '@/redux/store';
import { useEffect } from 'react';
import LineDivider from '../common/line-divider/line-divider';
import PTText from '../common/text/pt-text';
import Plan from './component/plan';
import styles from './plans.module.scss';

const paidPlans = [
  {
    planItems: [
      'Tú ingresas la información a nuestra plataforma con las indicaciones de nuestro sistema.',
      'Chat de ayuda con experto en precios de transferencia que resuelve tus dudas generales.',
      'El tiempo de elaboración será a tu ritmo. Si decides detenerte en algún momento tu información quedará guardada y podrás retomar cuando quieras.'
    ],
    plan: 'Plan PT-PRO',
    code: 'PRO',
    isPro: true,
    button: '¡Adquiere tu plan PT-PRO!',
    isEliteDiamond: false,
  },
  {
    planItems: [
      'Todo lo que incluye el plan PT-PRO.',
      'Cuando termines de ingresar la información a nuestro sistema podrás agendar una reunión virtual de hasta dos horas con un consultor experto en precios de transferencia, para una asesoría personalizada.',
    ],
    plan: 'Plan Convencional',
    code: 'CON',
    isPro: false,
    button: '¡Adquiere tu plan Convencional!',
    isEliteDiamond: false,
  },
  {
    planItems: [
      'Todo lo que incluye el plan PT-PRO.',
      'Durante todo el proceso tendrás a tu disposición un consultor experto en precios de transferencia que te asesorará de forma personalizada, en reunión virtual de hasta cuatro horas.',
    ],
    plan: 'Plan Élite Diamante',
    code: 'ELI',
    isPro: false,
    button: '¡Adquiere tu plan Élite Diamante!',
    isEliteDiamond: true,
  },
];

export default function Plans() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(findProducts());
  }, [dispatch]);


  return (
    <div className={`${styles.grade} ${styles.noPaddingTop}`}>
      <div className={styles.declaracionPlansWrapper}>
        <div className={styles.sectionIntro}>
          <LineDivider />
          <PTText
            asTag="h1"
            size="xxxl"
            weight="700"
            className={styles.headingPlans}
          >
            Planes TP TRIP para la elaboración de la Declaración de Precios de
            Transferencia
          </PTText>
          <PTText
            size="sm"
            weight="500"
            className={`${styles.title} ${styles.caps} ${styles.price}`}
          >
            Prepara la Declaración de Precios de
            Transferencia de manera segura y sin obstáculos.
          </PTText>
        </div>

        <div className={styles.wColumns}>
          <div className={`${styles.wCol} ${styles.wCol6} ${styles.wCol8}`}>
            <div className={styles.estandarAndExpres}>
              {paidPlans.map((paidPlan) => (
                <Plan key={paidPlan.plan} paidPlan={paidPlan} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
