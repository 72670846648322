import { useAppDispatch, useAppSelector } from '@/redux/store';
import { useEffect, useRef, useState } from 'react';
import md5 from 'md5';
import PTButton from '../common/button/pt-button';
import { useRouter } from 'next/router';
import styles from './payment-form.module.scss';
import { confirmDialog } from 'primereact/confirmdialog';
import { api } from '@/helpers/api.helper';
import { setToast } from '@/redux/slices/common.slice';

interface PaymentFormProps {
  description: string;
  amount: number;
  productCode: string;
  reference: string;
  buttonText: string;
  btnStyle?: any;
  dialog: boolean;
  redirectUrl: string;
}

export default function PaymentForm({
  description,
  amount,
  productCode,
  reference,
  buttonText,
  btnStyle,
  dialog = false,
  redirectUrl,
}: PaymentFormProps) {
  const router = useRouter();
  const { user } = useAppSelector((state) => state.session);
  const [signature, setSignature] = useState<string>();
  const formRef = useRef<HTMLFormElement>(null);
  const dispatch = useAppDispatch();
  const route = useRouter();

  const convertMD5 = (reference: string, amount: number) => {
    const rawSignature = `${process.env.NEXT_PUBLIC_PAYU_KEY}~${process.env.NEXT_PUBLIC_PAYU_BASE_MERCHANT_ID}~${reference}~${amount}~COP`;
    const encodedVal = md5(rawSignature);
    setSignature(encodedVal);
  };

  const handleFreeAccess = async () => {
    const resp = await api.post('payments/confirmation', {
      extra1: productCode,
      response_message_pol: 'FREE_APPROVED',
      state_pol: 99,
      payment_method: 'free',
      value: amount,
      transaction_date: Date.now(),
      email_buyer: user?.email,
    });
    if (resp) {
      dispatch(
        setToast({
          severity: 'success',
          summary: 'Guardado',
          detail: `Felicidades, obtuvo su paquete ${description} correctamente`,
        }),
      );
    }
    route.push(`/portal/${user?.id}`);
  };

  const handleSubmitForm = () => formRef?.current?.submit();

  const redirectUrlVerifica = () => {
    router.push('/verifica');
  };

  const handleMessagePlan = (freeAccess: boolean) => {
    confirmDialog({
      header: '¡Importante!',
      message:
        'Recuerda que nuestros planes solo incluyen la preparación de la Declaración de Precios de Transferencia, por lo cual, en caso de que detectemos que tu Compañía tiene obligaciones adicionales, nos contactaremos contigo para guiarte sin complicaciones en este viaje o si quieres puedes adquirir nuestro servicio de Verificación.',
      style: { width: '45%' },
      acceptLabel: 'Continuar',
      rejectLabel: 'Servicio de Verificación',
      accept: freeAccess ? () => handleFreeAccess() : () => handleSubmitForm(),
      reject: redirectUrlVerifica,
    });
  };

  const handleRedirectLogin = () => {
    localStorage.setItem('redirectUrl', redirectUrl);
    router.push('/login');
  };

  useEffect(() => {
    convertMD5(reference, amount);
  }, [reference, amount]);

  return (
    <>
      {user?.email ? (
        !user?.hasFreeServices ? (
          <>
            <form
              ref={formRef}
              method="post"
              action={`${process.env.NEXT_PUBLIC_PAYU_BASE_URI}/ppp-web-gateway-payu/`}
            >
              <input
                type="hidden"
                name="merchantId"
                value={process.env.NEXT_PUBLIC_PAYU_BASE_MERCHANT_ID}
              />
              <input
                type="hidden"
                name="accountId"
                value={process.env.NEXT_PUBLIC_PAYU_BASE_ACCOUNT_ID}
              />
              <input type="hidden" name="description" value={description} />
              <input type="hidden" name="referenceCode" value={reference} />
              <input type="hidden" name="amount" value={amount} />
              <input type="hidden" name="extra1" value={productCode} />
              <input type="hidden" name="currency" value="COP" />
              <input type="hidden" name="signature" value={signature} />
              <input type="hidden" name="algorithmSignature" value="MD5" />
              <input
                type="hidden"
                name="test"
                value={process.env.NEXT_PUBLIC_PAYU_TEST}
              />
              <input type="hidden" name="buyerEmail" value={user?.email} />
              <input
                type="hidden"
                name="responseUrl"
                value={`${window.location.origin}/respuesta-pago`}
              />
              <input
                type="hidden"
                name="confirmationUrl"
                value={`${process.env.NEXT_PUBLIC_BASE_API_URL}/payments/confirmation`}
              />
            </form>
            <PTButton //BUTTON PAY U
              style={btnStyle}
              size="md"
              isMain
              onClick={() =>
                dialog ? handleMessagePlan(false) : handleSubmitForm
              }
              className={styles.submitButton}
            >
              {buttonText}
            </PTButton>
          </>
        ) : (
          <PTButton //BUTTON FREE ACCESS
            style={btnStyle}
            size="md"
            isMain
            onClick={() =>
              dialog ? handleMessagePlan(true) : handleFreeAccess
            }
            className={styles.submitButton}
          >
            {buttonText}
          </PTButton>
        )
      ) : (
        <PTButton //BUTTON LOGIN
          style={btnStyle}
          size="md"
          isMain
          onClick={handleRedirectLogin}
          className={styles.submitButton}
        >
          {buttonText}
        </PTButton>
      )}
    </>
  );
}
